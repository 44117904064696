<template>
    <van-field
        readonly
        clickable
        :name="name"
        v-model="textValue"
        :label="label"
        :placeholder="placeholder"
        @click="click"
        :required="required"
        :rules="rules"
    ></van-field>
    <van-calendar
        v-model:show="showCalendar"
        teleport="body"
        :min-date="minDate"
        :max-date="maxDate"
        :show-confirm="false"
        @confirm="onDateConfirm"
        ref="calendar"
    ></van-calendar>
</template>

<script>
import {ref} from "vue";
import _ from "lodash";
import {Toast} from "vant";

export default {
  props: {
    name: {
      default: "picker",
      type: String
    },
    value: {
      default: "",
      type: String
    },
    placeholder: {
      default: "选择日期",
      type: String
    },
    label: {
      default: "日期",
      type: String
    },
    required: {
      default: false,
      type: Boolean
    },
    rules: {
      default: null,
      type: Array
    },
    minDate: {
      default: new Date().dateAdd('y', -5),
      type: Date
    },
    maxDate: {
      default: new Date().dateAdd('y', 5),
      type: Date
    },
  },
  data() {
    return {
      showCalendar: false,
      textValue: '',
    };
  },
  methods: {
    onDateConfirm(date) {
      this.$emit('update:value', date.getFullYear() + '-' + ((date.getMonth() + 1)> 9 ? (date.getMonth() + 1): '0' + (date.getMonth() + 1)) + '-'
          + (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()));
      this.showCalendar = false;
      this.$emit('onDateConfirm', date);
    },
    click() {
      var value = this.$props.value;
      if (!_.isEmpty(value)) {
        this.textValue = value;
        this.$refs.calendar.reset(new Date(value));
      }
      this.showCalendar = true
    }
  },
  created() {
    var value = this.$props.value;
    if (!_.isEmpty(value)) {
      this.textValue = value;
    }
  },
  mounted() {

  },
  computed: {
  },
  watch: {
    value:function(val,oldval){
      this.textValue = val;
    }
  }
}
</script>

<style scoped>

</style>