<template>
    <van-field
        readonly
        clickable
        :name="name"
        v-model="textValue"
        :label="label"
        :placeholder="placeholder"
        @click="click"
        :required="required"
        :rules="rules"
        :label-width=labelWidth
    ></van-field>

    <van-popup v-model:show="showPicker"
               :style="{ height: '50%' }"
               position="bottom"
               :lazy-render='false'
               teleport="body"
    >
      <van-datetime-picker
          v-model="datetime"
          :type="type"
          :title="label"
          :min-date="minDate"
          :max-date="maxDate"
          :min-hour="minHour"
          :max-hour="maxHour"
          :min-minute="minMinute"
          :max-minute="maxMinute"
          :formatter="formatter"
          :filter="filter"
          @change="onDateTimeChange"
          @cancel="onDateTimeCancel"
          @confirm="onDateTimeConfirm"
      />
    </van-popup>
</template>

<script>
import {ref} from "vue";
import _ from "lodash";
import {Toast} from "vant";

export default {
  props: {
    name: {
      default: "dateTime",
      type: String
    },
    value: {
      default: "",
      type: String
    },
    placeholder: {
      default: "选择时间",
      type: String
    },
    label: {
      default: "时间",
      type: String
    },
    labelWidth: {
      default: 100,
      type: Number
    },
    required: {
      default: false,
      type: Boolean
    },
    rules: {
      default: null,
      type: Array
    },
    type: {
      default: 'datetime',
      type: String,
      validator: value => {
        return [
          'datetime',
          'date',
          'time',
          'year-month',
          'month-day',
          'datehour'
        ].includes(value)
      }
    },
    minDate: {
      default: new Date().dateAdd('y', -5),
      type: Date
    },
    maxDate: {
      default: new Date().dateAdd('y', 5),
      type: Date
    },
    minHour: {
      default: 0,
      type: [Number, String],
    },
    maxHour: {
      default: 23,
      type: [Number, String],
    },
    minMinute: {
      default: 0,
      type: [Number, String],
    },
    maxMinute: {
      default: 59,
      type: [Number, String],
    },
    filter: {
      default: null,
      type: Function
    }
    //filter(type, options) {
      // if (type === 'minute') {
      //   return options.filter((option) => Number(option) % 5 === 0);
      // }
      //return options;
    //},
  },
  data() {
    return {
      textValue: '',
      showPicker: false,
      datetime: new Date(),
    };
  },
  methods: {
    onDateTimeChange(value) {
      this.$emit('onDateTimeChange', value);
    },
    onDateTimeCancel(date) {
      this.showPicker = false;
      this.$emit('onDateTimeCancel', date);
    },
    onDateTimeConfirm(value) {
      this.setTextValue(value);
      this.$emit('update:value', this.textValue);
      this.showPicker = false;
      this.$emit('onDateTimeConfirm', this.textValue);
    },
    formatter(type, val) {
      if (type === 'year') {
        return val + '年';
      }
      if (type === 'month') {
        return val + '月';
      }
      if (type === 'day') {
        return val + '日';
      }
      if (type === 'hour') {
        return val + '时';
      }
      if (type === 'minute') {
        return val + '分';
      }
      if (type === 'second') {
        return val + '秒';
      }
      return val;
    },
    setTextValue(val) {
      switch (this.type) {
        case 'datetime':
          this.textValue = val.getFullYear() + '-' + ((val.getMonth() + 1) > 9 ? (val.getMonth() + 1) : '0' + (val.getMonth() + 1)) + '-'
              + (val.getDate() > 9 ? val.getDate() : '0' + val.getDate()) + " "
              + val.getHours() + ":" + (val.getMinutes() > 9 ? val.getMinutes() : "0" + val.getMinutes()) + ":" + "00";
          break;
        case 'date':
          this.textValue = val.getFullYear() + '-' + ((val.getMonth() + 1) > 9 ? (val.getMonth() + 1) : '0' + (val.getMonth() + 1)) + '-'
              + (val.getDate() > 9 ? val.getDate() : '0' + val.getDate());
          break;
        case 'time':
          this.textValue = val;
          break;
        case 'year-month':
          this.textValue = val.getFullYear() + '-' + ((val.getMonth() + 1) > 9 ? (val.getMonth() + 1) : '0' + (val.getMonth() + 1));
          break;
        case 'month-day':
          this.textValue = ((val.getMonth() + 1) > 9 ? (val.getMonth() + 1) : '0' + (val.getMonth() + 1)) + '-' + val.getDate();
          break;
        case 'datehour':
          this.textValue = val.getFullYear() + '-' + ((val.getMonth() + 1) > 9 ? (val.getMonth() + 1) : '0' + (val.getMonth() + 1)) + '-'
              + (val.getDate() > 9 ? val.getDate() : '0' + val.getDate()) + " "
              + val.getHours();
          break;
      }
    },
    setDatetime() {
      var value = this.$props.value;
      if (!_.isEmpty(value)) {
        this.textValue = value;
        switch (this.type) {
          case 'datetime':
          case 'date':
          case 'year-month':
          case 'month-day':
            this.datetime = new Date(value);
            break;
          case 'time':
            this.datetime = value;
            break;
          case 'datehour':
            this.datetime = new Date(value + ":");
            break;
        }
      }
    },
    click() {
      this.setDatetime();
      this.showPicker = true
    }
  },
  created() {
    this.setDatetime();
  },
  mounted() {

  },
  computed: {},
  watch: {
    value: function (val, oldVal) {
      this.textValue = val;
    }
  }
}
</script>

<style scoped>

</style>