<template>
  <div>
    <van-form @submit="onSubmit">
      <van-cell-group title="拜访信息" inset>
        <van-field name="main" label="是否工头">
          <template #input>
            <van-switch
                name="main"
                v-model="main"
                size="20"
            ></van-switch>
          </template>
        </van-field>
        <Picker
            v-if="!main"
            name="mainCode"
            label="主预约编号"
            placeholder="请选择主预约编号"
            valueField="code"
            displayField="code"
            :required=true
            v-model:value="mainCode"
            :defConfig="{
            portName: 'osp.visitor.VisitorReserve.getVisitorReserves',
            data: {'visitReasonDic.code': 'ZYSG', 'main': true, 'statusCodes': ['VISITOR_RESERVE_STATUS_DEFAULT', 'VISITOR_RESERVE_STATUS_REJECT']},
            }"
            :rules="[{ required: true, message: '请选择主预约编号' }]"
            :canSearch=true
            @onConfirm="onConfirmMainCode"
        />

        <van-field
            readonly
            name="code"
            v-model="code"
            label="预约编号"
            placeholder="自动生成"
        ></van-field>

        <DatePicker
            name="planTime"
            label="预约日期"
            v-model:value="planTime"
            :min-date="minDate"
            :required=true
            :rules="[{ required: true, message: '请选择访问时间' }]"
        >
        </DatePicker>
        <DatetimePicker
            name="startTime"
            v-model:value="startTime"
            type="time"
            label="预计拜访时间"
        />
        <DatetimePicker
            name="endTime"
            v-model:value="endTime"
            type="time"
            label="预计结束时间"
        />

        <Picker
            name="visitReasonDic.id"
            label="拜访事由"
            placeholder="请选择拜访事由"
            displayField="name"
            :required=true
            :select-first="true"
            v-model:value="this.$data['visitReasonDic.id']"
            :defConfig="{
              portName: 'system.foundation.Dic.getChildDicsByCode',
              data: {code: 'VISIT_REASON', needCodes: ['ZYSG']},
            }"
            :rules="[{ required: true, message: '请选择拜访事由' }]"
            :canSearch=false
        ></Picker>


        <van-field name="carryCar" label="是否驾车入园"
                   :required=true
                   label-width="32vw"
                   :rules="[{ required: true, message: '请选择是否驾车入园' }]"
                   v-model:value="isCarryCar"
        >
          <template #input>
            <van-radio-group v-model="isCarryCar" direction="horizontal">
              <van-radio name="true">是</van-radio>
              <van-radio name="false">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <Picker
            v-if="carryCar"
            name="carNum"
            label="车牌号"
            placeholder="请选择车辆"
            displayField="carNum"
            valueField="carNum"
            v-model:value="carNum"
            :defConfig="{
              portName:'osp.visitor.VisitorCarEntity.getVisitorCars',
                data: {'visitor.id': this.$data['visitor.id'], 'typeDic.code': 'VISITOR_CAR_TYPE_NOT_TRUCK'},

            }"
            :rules="[{ required: true, message: '请选择车辆' }]"
            :canSearch=false
            :defSelectValCallback="function(raw) {
              if (raw.default){
                return true;
              }
            }"
            @onConfirm="onCarConfirm"
        ></Picker>
        <van-field
            v-show="carryCar"
            readonly
            name="carBrand"
            v-model="carBrand"
            label="车辆型号"
        ></van-field>

      </van-cell-group>
      <van-cell-group title="人员信息" icon="friends-o" inset>
        <van-field
            v-model="name"
            name="name"
            readonly
            label="来访人员"
            placeholder="来访人员"
            required
            :rules="[{ required: true, message: '请填写来访人员' }]"
        ></van-field>
        <van-field
            v-model="tel"
            name="tel"
            label="电话号码"
            placeholder="电话号码"
        ></van-field>
        <van-field
            v-model="unit"
            name="unit"
            readonly
            label="所属单位"
            placeholder="所属单位"
        ></van-field>


        <van-field
            v-model="originArea"
            name="originArea"
            label="来源地区"
            placeholder="来源地区"
            required
            :rules="[{ required: true, message: '请填写来源地区' }]"
        ></van-field>


        <van-field name="socialSecurityAttach" label="社保证明附件">
          <template #input>
            <van-uploader
                v-model="socialSecurityAttach"
                :before-read="beforeRead"
                :after-read="afterRead"
            ></van-uploader>
          </template>
        </van-field>

        <van-field name="qualificationAttach" label="资质附件">
          <template #input>
            <van-uploader
                v-model="qualificationAttach"
                :before-read="beforeRead"
                :after-read="afterRead"
            ></van-uploader>
          </template>
        </van-field>


        <van-field name="specialWork" label="有无特殊作业">
          <template #input>
            <van-switch
                name="specialWork"
                v-model="specialWork"
                size="20"
            ></van-switch>
          </template>
        </van-field>

        <van-field name="insuranceAttach" label="意外保险" v-if="specialWork">
          <template #input>
            <van-uploader
                v-model="insuranceAttach"
                :before-read="beforeRead"
                :after-read="afterRead"
            ></van-uploader>
          </template>
        </van-field>

        <van-field name="workDay" label="工期15天以上">
          <template #input>
            <van-switch
                name="workDay"
                v-model="workDay"
                size="20"
            ></van-switch>
          </template>
        </van-field>


        <van-field name="inspectAttach" label="健康体检" v-if="workDay">
          <template #input>
            <van-uploader
                v-model="inspectAttach"
                :before-read="beforeRead"
                :after-read="afterRead"
            ></van-uploader>
          </template>
        </van-field>

        <van-field name="remarkAttach" label="附件">
          <template #input>
            <van-uploader name="remarkAttach" v-model="remarkAttach"
                          :before-read="beforeRead"
                          :after-read="afterRead"
                          max-count="1"></van-uploader>
          </template>
        </van-field>
        <div style="padding-left: 2.66667vw;padding-right: 2.66667vw;font-size: 4vw">备注：1、外工入职体检项目统一为内科，血压，外科，五官科，血常规，心电图，肝功能五顶，全胸片（体检日期为3个月内）
        2、携带身份证进入厂区登记</div>



      </van-cell-group>


      <div style="margin: 4.26667vw">
        <van-button round block type="primary" native-type="submit" :disabled="btnDisabled">
          保存
        </van-button>
      </div>
      <div style="margin: 4.26667vw" v-if="main">
        <van-button round block type="primary" @click="submitApprove" :disabled="btnDisabled">
          提交预约
        </van-button>
      </div>
    </van-form>
  </div>
  <van-dialog v-model:show="showDialog" title="HSE守则">
    <div style="margin: 4.8vw;height: 106.66667vw;overflow: auto">
      <p style="white-space: pre-line;">{{hseText}}</p>
    </div>
  </van-dialog>

</template>

<script>
import {Toast} from "vant";
import {ref} from "vue";
import _ from "lodash";
import Picker from "../../components/Picker";
import DatePicker from "../../components/DatePicker";
import DatetimePicker from "../../components/DatetimePicker";


export default {
  components: {DatetimePicker, Picker, DatePicker},
  name: "VisitorReserve",
  data() {
    return {
      id: '',
      'visitor.id': '',
      code: '',
      main: false,
      mainCode: '',
      'interviewedDepart.id': '',
      'interviewedUser.id': '',
      isCarryCar: 'false',
      planTime: "",
      startTime: '10:00',
      endTime: '12:00',
      interviewedWorkNum: "",
      'visitReasonDic.id': '',
      visitReason: "",
      name: "",
      tel: "",
      unit: "",
      carNum: "",
      carBrand: "",
      originArea: "",
      faceIDAttach: [],
      healthCodeAttach: [],
      socialSecurityAttach: [],
      qualificationAttach: [],
      remarkAttach: [],
      specialWork: '',
      insuranceAttach: [],
      workDay: '',
      inspectAttach: [],
      btnDisabled: false,
      showDialog: false,
      minDate: new Date(),
      hseText: ''
    };
  },
  computed:{
    carryCar: function () {
      return this.isCarryCar == 'true'
    }
  },
  methods: {
    loadData() {
      var me = this;
      var id = me.$route.params.id;
      if (!_.isEmpty(id)) {
        var option = {
          portName: 'osp.visitor.VisitorReserve.getVisitorReserve',
          data: {id: id},
          needLoading: false,
          successCallback: function (data) {
            var entities = data.entities;
            if (!_.isEmpty(entities)) {
              var entity = entities[0];
              var _data = me.$data;
              for (var attrName in entity) {
                if (_data[attrName] != undefined) {
                  _data[attrName] = entity[attrName];
                }
              }
              var planTime = entity.planTime;
              if (!_.isEmpty(planTime)) {
                planTime = planTime.replace(/-/g,'/');
              }
              _data.planTime = _.isEmpty(planTime) ? "" : new Date(planTime).pattern('yyyy-MM-dd').toString();
              if (entity['statusDic.code'] !== 'VISITOR_RESERVE_STATUS_DEFAULT' && entity['statusDic.code'] !== 'VISITOR_RESERVE_STATUS_REJECT') {
                me.btnDisabled = true;
              } else {
                me.btnDisabled = false;
              }
            }
          }
        }
        this.$sapi.callPort(option);
      } else {
        me.showDialog = true;
        me.btnDisabled = false;
        var visitor = this.$sapi.storage.getItemJson('visitor');
        this['visitor.id'] = visitor.id;
        this.name = visitor.name;
        this.tel = visitor.tel;
        this.unit = visitor.unit;
      }
    },
    onSubmit(values) {
      values['id'] = this.id;
      values['visitor.id'] = this['visitor.id'];
      var option = {
        portName: 'osp.visitor.VisitorReserve.saveVisitorReserve',
        data: values,
        needLoading: false,
        successCallback: (data) => {
          this.id = data.data.id;
          this.$notify({type: 'success', message: '保存成功'});
        },
        finallyCallback: () => {
          this.btnDisabled = false;
        }
      };
      this.btnDisabled = true;
      this.$sapi.callPort(option);
    },
    submitApprove() {
      var me = this;
      var id = me.id;
      if (_.isEmpty(id)) {
        Toast.fail('请先保存');
        return;
      }
      var option = {
        portName: 'osp.visitor.VisitorReserve.submitVisitorReserve',
        data: {ids: [id]},
        needLoading: false,
        successCallback: (data) => {
          me.id = data.data.id;
          this.$dialog.alert({
            title: '',
            message: '提交成功',
          }).then(() => {
            me.$router.go(-1);
          });
        },
        finallyCallback: () => {
          this.btnDisabled = false;
        }
      };
      this.btnDisabled = true;
      this.$sapi.callPort(option);
    },
    onUserConfirm(value) {
      this.interviewedWorkNum = value.workNumber;
    },
    onCarConfirm(value) {
      this.carBrand = value.brand;
    },
    beforeRead: function (file) {
      return this.$sapi.compressImg(file, 200, true);
    },
    afterRead: function (file) {
      this.$sapi.setUploadFileModel(file);
    },
    onConfirmMainCode(value) {
      value.planTime = value.planTime.replace(/-/g,'/');
      this.planTime = new Date(value.planTime).pattern('yyyy-MM-dd').toString();
      this.startTime = value.startTime;
    },
    loadHseText() {
      var option = {
        portName: 'system.foundation.Dic.getDicDetail',
        data: {code: 'HSE_RULES'},
        needLoading: false,
        successCallback: (data) => {
          this.hseText = data.entities[0].bigContent;
        }
      };
      this.$sapi.callPort(option);
    }
  },
  created: function () {
    var me = this;
    this.loadData();
    this.loadHseText();
  },
  mounted() {

  },
  watch: {
    carryCar: function (val) {
      if (!val) {
        this.carNum = '';
        this.carBrand = '';
      }
    },
    main: function (val) {
      if (!val) {
        this.mainCode = '';
      }
    }
  }
};
</script>

<style>
</style>